import React from 'react';

const Navigation = () => {
  return (
    <nav>
      <ul>
        <li><a href="#about">About</a></li>
        <li><a href="#guidelines">Guidelines</a></li>
        <li><a href="#contribute">Contribute</a></li>
        <li><a href="#files">Files</a></li>
        <li><a href="/styles/">All Styles</a></li>
        <li><a href="/resources/">Resources</a></li>
      </ul>
    </nav>
  );
};

export default Navigation;