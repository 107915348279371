import React from 'react';

const Styles = () => {
  return (
    <aside id="styles">
      <div className="container">
        <h2>Featured Styles</h2>
        <ul className="features">
          <li>
            <span>
              <a href="/styles/web-2.0-lobster-party">
                Web 2.0 Lobster Party<span aria-hidden="true"></span>
              </a>
              <span>by Andy Bell</span>
            </span>
          </li>
          <li>
            <span>
              <a href="/styles/retroish">
                Retroish<span aria-hidden="true"></span>
              </a>
              <span>by Jean Louise Tiston</span>
            </span>
          </li>
          <li>
            <span>
              <a href="/styles/transparency">
                Transparency<span aria-hidden="true"></span>
              </a>
              <span>by Jens Oliver Meiert</span>
            </span>
          </li>
        </ul>

        <a href="/styles/" className="link-allstyles">
          View All Styles
        </a>
      </div>
    </aside>
  );
};

export default Styles;