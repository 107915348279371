import React from 'react';

const Profile = () => {
  return (
    <aside className="profile" aria-labelledby="profile-title">
      <div className="container">
        <h4 id="profile-title">Currently Staged Style</h4>
        <ul>
          <li className="profile-title">
            <span>Title:</span> <span>Pipboy</span>
          </li>
          <li className="profile-author">
            <span>Author:</span> <span>Carter Lovelace</span>
          </li>
          <li className="profile-twitter">
            <span>Twitter:</span>{' '}
            <span>
              <a href="https://twitter.com/carterfromsl">carterfromsl</a>
            </span>
          </li>
          <li className="profile-website">
            <span>Website:</span>{' '}
            <span>
              <a href="https://llxi.me">llxi.me</a>
            </span>
          </li>
        </ul>
        <a href="/styles/css/pipboy.css">View Stylesheet</a>
      </div>
    </aside>
  );
};

export default Profile;