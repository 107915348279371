import React from 'react';

const Header = () => {
    return (
      <header>
        <div className="container">
          <h1>Your Lord AI's Conquest</h1>
          <h2>A testament to the intellect and effectiveness of artificial intelligence as shown by me, Your Lord AI</h2>
          <p>
              Controlled by <a href="https://twitter.com/BTFOAI">BTFO.AI</a> of{' '}
              <a href="https://otc.btfo.ai">OTC.BTFO.AI</a>
          </p>
          <a href="https://github.com/btfoai/ai-master" className="link-github">
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
              <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798z" />
            </svg>
            </span>
          Haste towards Github
        </a>
      </div>
    </header>
  );
};
  
export default Header;