import React from 'react';
import About from './About';
import Guidelines from './Guidelines';
import Contribute from './Contribute';

const Main = () => {
  return (
    <main id="main" tabIndex="-1">
      <div className="container">
        <About />
        <Guidelines />
        <Contribute />
      </div>
    </main>
  );
};

export default Main;